<template>
    <b-overlay :show="loading">
    <div class="form-wrapper tea-garden-profile-wrapper">
        <b-row>
            <b-col lg="12" sm="12">
                <body-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <table v-if="id" class="table table-borderless">
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.garden_name')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ getTeaGardenName(data.garden_id) }}</td>
                                    </tr>
                                     <tr>
                                        <th style="width: 25%">{{$t('globalTrans.status')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ getStatusName(data.status) }}</td>
                                    </tr>
                                     <tr>
                                        <th style="width: 25%">{{$t('teaGardenBtriService.tentative_date')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ data.tentative_date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="data.description_en || data.description_bn">
                                        <th style="width: 25%">{{$t('teaGardenConfig.description')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">
                                            <div v-if="currentLocal === 'en'" v-html="data.description_en" style="padding: 0px;"></div>
                                            <div v-else v-html="data.description_bn"></div>
                                        </td>
                                    </tr>
                                     <tr v-if="data.visit_date">
                                        <th style="width: 25%">{{$t('teaGardenBtriService.visit_date')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ data.visit_date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="data.visit_note_en || data.visit_note_bn">
                                        <th style="width: 25%">{{$t('globalTrans.note')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">
                                            {{ currentLocal === 'en' ? data.visit_note_en : data.visit_note_bn }}
                                        </td>
                                    </tr>
                                    <tr v-if="data.report_submit_date">
                                        <th style="width: 25%">{{$t('teaGardenBtriService.report_submit_date')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ data.report_submit_date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="data.attachment">
                                        <th style="width: 25%">{{$t('globalTrans.attachment')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">
                                            <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(data.attachment) ? 'storage/' : '') + data.attachment" class="moc-action-btn btn-primary" :title="$t('globalTrans.attachment')">
                                                <i class="ri-file-line"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>
                    </template>
                </body-card>
            </b-col>
        </b-row>
    </div>
    </b-overlay>
</template>
<script>
import { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
    name: 'Details',
    props: ['id'],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            valid: null,
            errors: [],
            data: {}
        }
    },
    created () {
        if (this.id) {
            const tmp = this.getDetailData()
            this.data = tmp
        }
    },
    computed: {
        currentLocal () {
            return this.$i18n.locale
        },
        authUser: function () {
            return this.$store.state.Auth.authUser
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        statusList () {
            const arr = [
            { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
            { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
            { value: 3, text: this.$t('globalTrans.confirm'), text_en: this.$t('globalTrans.confirm', 'en'), text_bn: this.$t('globalTrans.confirm', 'bn') },
            { value: 4, text: this.$t('globalTrans.visited'), text_en: this.$t('globalTrans.visited', 'en'), text_bn: this.$t('globalTrans.visited', 'bn') }
            ]
            return arr
        }
    },
    methods: {
        getStatusName (status) {
            const obj = this.statusList.find(item => item.value === parseInt(status))
            return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
        },
        EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
        getGenderName (id) {
            const Gender = this.$store.state.TeaGardenService.commonObj.gender.find(el => el.value === parseInt(id))
            if (Gender !== undefined) {
                return this.$i18n.locale === 'en' ? Gender.text_en : Gender.text_bn
            }
        },
        getDetailData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getTeaGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
            return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
        },
        isImage (path) {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
        }
    }
}
</script>
