<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(cloneAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.fiscal_year_id"
                :options="fiscalYearList"
                id="clone_info_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- garden -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="garden_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.garden_name') }} <span class="text-danger">*</span>
                </template>
                <v-select
                  id="garden_id"
                  :disabled="isGardenAdmin"
                  plain
                  v-model="data.garden_id"
                  :options="getGardenList"
                  :reduce="op => op.value"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                  >
                  </v-select>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Tentative Date" vid="tentative_date" rules="required">
                <b-form-group
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('teaGardenBtriService.tentative_date')}} <span class="text-danger">*</span>
                  </template>
                  <date-picker
                    id="tentative_date"
                    v-model="data.tentative_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12">
            <ValidationProvider name="Description (En)" vid="description_en" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="description_en">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.en') }} <span class="text-danger"> * </span>
                </template>
                <vue-editor
                  id="description_en"
                  v-model="data.description_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                 {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
           <b-col xs="12" sm="12">
            <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="description_bn">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.bn') }} <span class="text-danger"> * </span>
                </template>
                <vue-editor
                  id="description_bn"
                  v-model="data.description_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right mb-3">
          <b-col>
              <b-button type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
              <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { onlineAdvVisitStore, onlineAdvVisitUpdate } from '../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import { VueEditor } from 'vue2-editor'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  components: { VueEditor },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        fiscal_year_id: this.$store.state.currentFiscalYearId
      },
      isGardenAdmin: false
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditEntry()
      this.data = tmp
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.data.garden_id = this.isGardenAdminCheckGardenId()
      this.isGardenAdmin = true
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    getGardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.localizeUserList(newVal)
      }
    }
  },
  methods: {
    async saveUpdate (status = 0) {
    const isValid = await this.$refs.form.validate()
      this.data.status = status
      if (isValid) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${onlineAdvVisitUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, onlineAdvVisitStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.params.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.mainForm.setErrors(result.errors)
      }
    }
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getEditEntry () {
      const itemId = this.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
